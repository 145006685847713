import { AxosClient } from '~/codegen/axos-client';

const config = useRuntimeConfig();
const { fetchWithDefaults } = useHttpClient();

export const $api = new AxosClient(config.public.BFF_URL + '/api', {
	fetch(url, init) {
		return fetchWithDefaults(url, init);
	},
});
